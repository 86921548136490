<template>
  <div class="audienceList dp-flex flex-d-c align-item-end">
    <div
      class="audienceItem"
      :class="{ selected: selected }"
    >
      <div class="dp-flex justify-btw align-item-c title mb-16 w-100">
        <div>
          <span>{{ target.name }}</span>
          <span @click.stop>
            <el-switch
              class="ml-10"
              v-model="target.isCloseAdset"
              :active-value="false"
              :inactive-value="true"
            ></el-switch>
          </span>
        </div>
        <div>
          <el-button
            type="text"
            @click="$emit('copy')"
            >复制</el-button
          >
          <el-button
            type="text"
            @click="$emit('deleteTarget')"
            >删除</el-button
          >
        </div>
      </div>
      <div>
        <span
          class="tips"
          v-show="target.min_age || target.max_age"
          >{{ target.min_age }}~{{ target.max_age }}</span
        >
        <span class="ml-10 tips">{{ Gender[target.gender] }}</span>
        <span class="ml-10 tips">{{ showFlexible }}</span>
      </div>
    </div>
  </div>
</template>
<script>
import { Gender } from '@/utils/enmu';
export default {
  props: {
    target: {
      type: Object,
      default: () => ({}),
    },
    selected: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    showFlexible() {
      const keys = Object.keys(this.target.flexible_spec || {});
      let list = [];
      keys.forEach((item) => {
        this.target.flexible_spec[item].forEach((flexible) => {
          list.push(flexible.name);
        });
      });
      return list.join('、');
    },
  },
  data() {
    return {
      Gender,
    };
  },
};
</script>
<style lang="scss" scoped>
.audienceList {
  .audienceItem {
    width: 400px;
    padding: 15px;
    border: 1px solid #eee;
    border-radius: 8px;
    background: #fafafa;
    &.selected {
      border: 1px solid #409eff;
    }
  }
}
</style>
