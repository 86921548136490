<template>
  <div class="fb">
    <!-- 商品信息 -->
    <sale
      :recommendSale="deliveryData.recommendSale"
      :pro_id="data.pro_id"
      @changeRecommendSale="changeRecommendSale"
    ></sale>

    <!-- 素材 -->
    <material
      :sample_videos="data.sample_videos"
      :use_diff_slogan="use_diff_slogan"
      @changeUse_diff_slogan="changeUse_diff_slogan"
      :data="data"
      ref="material"
    ></material>

    <!-- 广告资产 -->
    <property
      :adAsset="deliveryData.adAsset"
      @changeAdAsset="changeAdAsset"
      @changeSelectCollMes="changeSelectCollMes"
      :selectCollMes="selectCollMes"
      ref="property"
    ></property>
    <!-- 系列预算 -->
    <series
      :campaign="deliveryData.campaign"
      @changeCampaign="changeCampaign"
      ref="series"
    ></series>
    <!-- 受众  -->
    <audience-edit
      :targetList="targetList"
      :editTargetIndex="editTargetIndex"
      :data="deliveryData"
      :selectCollMes="selectCollMes"
      @addTarget="addTarget"
      @deleteTarget="deleteTarget"
      @selectEdlitTarget="selectEdlitTarget"
      @copyTarget="copyTarget"
    ></audience-edit>
    <div class="bottom">
      <el-button
        type="primary"
        size="medium"
        @click="delivery"
        >投放</el-button
      >
    </div>
  </div>
</template>
<script>
import material from './fb/Material';
import property from './fb/property';
import Series from './fb/Series';
import AudienceEdit from './fb/AudienceEdit';
import sale from './fb/sale.vue';

import { autoVideoAd } from '@/api/fastDelivery';
import moment from 'moment';
export default {
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    material,
    property,
    Series,
    AudienceEdit,
    sale,
  },
  data() {
    return {
      use_diff_slogan: false, // 是否使用程序化创意
      targetList: [
        {
          name: '受众名称1',
          gender: 'GENDER_UNLIMITED',
          isCloseAdset: false,
          min_age: 18,
          max_age: 65,
          flexible_spec: {},
        },
      ], // 受众
      editTargetIndex: 0, // 编辑的受众
      deliveryData: {
        adAsset: {
          call_to_action: 'SHOP_NOW',
        },
        campaign: {
          // 系列预算
          budget_optimize_switch: false, // 开启系列预算优化
          schedule_type: 'SCHEDULE_START_END', // 是否持续投放
          bid_strategy: 'LOWEST_COST_WITHOUT_CAP',
          schedule_start_time: moment(new Date()).format('yyyy-MM-DD HH:mm:ss'),
        },
        recommendSale: this.data.sale_id, // 商品id
      },
      addTargetIndex: 1,
      selectCollMes: {}, // 选择的站点信息
    };
  },
  methods: {
    // 修改商品ID
    changeRecommendSale(recommendSale) {
      this.$set(this.deliveryData, 'recommendSale', recommendSale);
    },
    changeUse_diff_slogan(val) {
      this.use_diff_slogan = val;
    },
    // 修改广告资产
    changeAdAsset(key, val) {
      this.$set(this.deliveryData.adAsset, key, val);
    },
    // 修改系列
    changeCampaign(key, val) {
      this.$set(this.deliveryData.campaign, key, val);
    },
    // 添加受众
    addTarget() {
      this.targetList.push({
        name: '新增受众' + this.addTargetIndex,
        gender: 'GENDER_UNLIMITED',
        min_age: 18,
        max_age: 65,
        isCloseAdset: false,
        flexible_spec: {},
      });
      this.addTargetIndex += 1;
    },
    // 删除受众
    deleteTarget(index) {
      if (this.targetList.length <= 1) {
        this.$message.error('至少保留一个');
        return;
      }
      this.targetList.splice(index, 1);
      if (index < this.editTargetIndex) {
        this.editTargetIndex -= 1;
      }
    },
    // 切换编辑的受众
    selectEdlitTarget(index) {
      this.editTargetIndex = index;
    },
    // 复制受众
    copyTarget(index) {
      this.targetList.push({
        ...this.targetList[index],
        name: `${this.targetList[index].name}_copy`,
      });
    },
    // 修改选择的站点
    changeSelectCollMes(mes = {}) {
      let _mes = {
        ...mes,
        min_age: 18,
      };
      if (mes.country && mes.country.code == 'TH') {
        _mes.min_age = 20;
        this.targetList.forEach((item, index) => {
          if (item.min_age < 20) {
            this.$set(this.targetList[index], 'min_age', 20);
          }
        });
      }
      if (mes.country && mes.country.code == 'ID') {
        _mes.min_age = 21;
        this.targetList.forEach((item, index) => {
          if (item.min_age < 21) {
            this.$set(this.targetList[index], 'min_age', 21);
          }
        });
      }
      this.selectCollMes = _mes;
    },
    // 点击投放
    delivery() {
      //   表单验证
      console.log('this.deliveryData.recommendSale', this.deliveryData.recommendSale);
      if (!this.deliveryData.recommendSale) {
        this.$message.error('请填写商品信息');
        return;
      }
      this.$emit('setLoading', true);
      Promise.all([this.$refs.material.validate(), this.$refs.series.validate(), this.$refs.property.validate()])
        .then(() => {
          const {
            campaign: {
              budget_optimize_switch,
              budget,
              bid_strategy,
              bid_amount,
              dsa_payor,
              dsa_beneficiary,
              schedule_type,

              schedule_start_time,
              schedule_end_time,
            },
            adAsset: { call_to_action, ...adAsset },
          } = this.deliveryData;
          let _campaign = {
            budget_optimize_switch,
          };
          let _adgroups = {
            dsa_payor,
            dsa_beneficiary,
            schedule_type,
            schedule_start_time,
            schedule_end_time,
          };
          if (budget_optimize_switch) {
            _campaign = {
              ..._campaign,
              budget,
            };
          } else {
            _adgroups = {
              ..._adgroups,
              budget,
              bid_strategy,
              bid_amount: bid_amount * 100,
            };
          }
          // 处理素材
          const media_infos = this.data.sample_videos.map((item) => {
            return {
              media_type: 'VIDEO',
              image_url: item.cover,
              video_url: item.url,
            };
          });
          // 处理广告语
          const slogans = this.$refs.material.slogans.map((item) => ({
            title: item.title,
            content: item.message,
            description: item.description,
          }));
          // 处理广告组
          const lastAdgroups = this.targetList.map(({ isCloseAdset, flexible_spec, ...target }) => {
            let facebook_include_interests = [];
            Object.keys(flexible_spec).map((item) => {
              flexible_spec[item].map((i) => {
                facebook_include_interests.push({
                  id: i.id,
                  name: i.name,
                  type: i.type,
                  audience_size: i.audience_size,
                });
              });
            });
            return {
              ..._adgroups,
              isCloseAdset,
              targeting: {
                ...target,
                facebook_include_interests,
              },
            };
          });

          let params = {
            platform: 'facebook',
            language: this.data.lang_code,
            campaign: {
              ..._campaign,
            },
            country: this.selectCollMes.country.code,
            currencyCode: this.selectCollMes.currency.code,
            call_to_action,
            adAsset: {
              ...adAsset,
              sale_id: this.deliveryData.recommendSale,
            },
            recommendSale: this.deliveryData.recommendSale,
            prod_id: this.data.pro_id,
            prod_name: this.data.pro_name,
            media_infos,
            slogans,
            use_diff_slogan: this.use_diff_slogan,
            adgroups: lastAdgroups,
            is_upload: true,
          };
          autoVideoAd(params)
            .then((res) => {
              if (res.code == 0) {
                this.$message.success('投放成功');
                setTimeout(() => {
                  window.parent && window.parent.postMessage('delivery ok', '*');
                }, 3000);
              }
            })
            .finally(() => {
              this.$emit('setLoading', false);
            });
        })
        .catch((mess) => {
          this.$emit('setLoading', false);
          console.log('验证结果', mess);
        });
    },
  },
  watch: {
    data: {
      deep: true,
      immediate: true,
      handler(val) {
        this.$set(this.deliveryData, 'recommendSale', val.sale_id);
      },
    },
  },
};
</script>
<style lang="scss" scoped>
.fb {
  padding-bottom: 100px;
}
.bottom {
  border-top: 1px solid #eee;
  padding: 20px 20px 0;
  text-align: right;
  background: #fff;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}
</style>
