<template>
  <el-card class="audience mt-20">
    <div
      class="audienceName dp-flex justify-btw"
      solt="header"
    >
      <div>
        <div class="dp-flex align-items-c">
          <span v-show="!editName">{{ targetList[editTargetIndex].name }}</span>
          <i
            v-show="!editName"
            @click="editName = true"
            class="el-icon-edit-outline editName ml-10"
          ></i>
          <el-input
            v-show="editName"
            v-model="targetList[editTargetIndex].name"
            placeholder=""
            @keydown.native.enter="editName = false"
          ></el-input>
        </div>
      </div>
      <el-button
        type="text"
        class="align-self-end"
        @click="$emit('addTarget')"
        >添加受众</el-button
      >
    </div>
    <div class="dp-flex">
      <div class="flex-1">
        <div>
          <el-form
            :model="targetList[editTargetIndex]"
            inline
            label-width="80px"
            label-position="left"
          >
            <el-row>
              <el-col :span="23">
                <el-form-item
                  label="地区"
                  class="w-100 people"
                >
                  <span>{{ selectCollMes.country ? selectCollMes.country.name : '-' }}</span>
                </el-form-item>
              </el-col>
              <el-col :span="23">
                <el-form-item label="性别">
                  <el-radio-group v-model="targetList[editTargetIndex].gender">
                    <el-radio label="GENDER_UNLIMITED">所有性别</el-radio>
                    <el-radio label="GENDER_MALE">男</el-radio>
                    <el-radio label="GENDER_FEMALE">女</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
              <el-col :span="23">
                <div class="dp-flex align-item-end">
                  <el-form-item label="年龄">
                    <div class="dp-flex align-item-c">
                      <el-input-number
                        onKeyUp="value=value.replace(/[^\d]/g,'')"
                        :min="selectCollMes.min_age"
                        :max="65"
                        v-model.trim="targetList[editTargetIndex].min_age"
                        maxlength="50"
                        class="w-200"
                        ><template slot="append">岁</template></el-input-number
                      >
                      <div class="ml-10">~</div>
                    </div>
                  </el-form-item>

                  <el-form-item>
                    <el-input-number
                      onKeyUp="value=value.replace(/[^\d]/g,'')"
                      :min="selectCollMes.min_age"
                      :max="65"
                      v-model.trim="targetList[editTargetIndex].max_age"
                      maxlength="50"
                      placeholder="65"
                      class="w-200"
                      ><template slot="append">岁</template></el-input-number
                    >
                  </el-form-item>
                </div>
              </el-col>
              <el-col :span="23">
                <el-form-item
                  label="行为兴趣"
                  class="w-100 people"
                >
                  <div class="self_flex">
                    <div class="tips">包含符合以下任意条件的用户</div>
                    <flexible-spec
                      ref="flexibleSpec"
                      :accountId="data.adAsset.account_id"
                      :Thirdloginuserid="data.adAsset.user_id"
                      v-model="targetList[editTargetIndex].flexible_spec"
                      :canSetCommonSave="false"
                    ></flexible-spec>
                  </div>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
      </div>
      <div>
        <div
          v-for="(target, index) in targetList"
          :key="index"
          class="mb-10"
          @click="$emit('selectEdlitTarget', index)"
        >
          <audience
            :target="target"
            :selected="index === editTargetIndex"
            @deleteTarget="$emit('deleteTarget', index)"
            @copy="$emit('copyTarget', index)"
          ></audience>
        </div>
      </div>
    </div>
  </el-card>
</template>
<script>
import FlexibleSpec from '@/views/adManagement/createAd/components/targeting/FlexibleSpec';

import Audience from './Audience.vue';

export default {
  components: {
    FlexibleSpec,
    Audience,
  },
  props: {
    targetList: {
      type: Array,
      default: () => [],
    },
    editTargetIndex: {
      type: Number,
    },
    data: {
      type: Object,
      default: () => ({}),
    },
    selectCollMes: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      editName: false,
    };
  },
  watch: {
    editTargetIndex() {
      this.editName = false;
    },
  },
};
</script>
<style lang="scss" scope>
.audience {
  .editName {
    color: #409eff;
  }
  .w-200 {
    width: 200px;
  }
  .people {
    display: flex;
    .el-form-item__content {
      flex: 1;
      .el-select {
        width: 100%;
      }
    }
  }
}
</style>
